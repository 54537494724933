import { RecommendationStatus } from 'api/domain/entities/recommendation'
import { TenantStatus } from 'api/dto/tenant'

export function getStatusColor(status: RecommendationStatus) {
  const statusColor = {
    [RecommendationStatus.UNRESOLVED]: 'red',
    [RecommendationStatus.RESOLVED]: 'green',
    [RecommendationStatus.OUTDATED]: 'yellow',
  }

  return statusColor[status]
}

export function getTenantStatusColor(status: TenantStatus) {
  const statusColor = {
    [TenantStatus.ACTIVE]: 'green',
    [TenantStatus.INACTIVE]: 'red',
    [TenantStatus.ONBOARDING_STEP_1]: 'yellow',
    [TenantStatus.ONBOARDING_STEP_2]: 'yellow',
  }

  return statusColor[status]
}
