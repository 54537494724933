import { TextInput, Button, Text, Box, Anchor, Progress } from '@mantine/core'
import { UseFormReturnType, useForm } from '@mantine/form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { NS } from 'i18n'
import { PasswordProgress } from 'components/password/password-progress'

interface FormValues {
  firstName: string
  lastName: string
  tenantName: string
  email: string
  password: string
  confirmPassword: string
}

export const Register = () => {
  const { register } = useAuth()
  const { t } = useTranslation(NS.session)
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      confirmPassword: '',
      tenantName: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      firstName: (value) => (value.length > 0 ? null : 'First name is required'),
      lastName: (value) => (value.length > 0 ? null : 'Last name is required'),
      tenantName: (value) => (value.length > 0 ? null : 'Company name is required'),
      password: (value) => (value.length >= 8 ? null : 'Password is too short'),
      confirmPassword: (value, values) => (value === values.password ? null : 'Passwords do not match'),
    },
  })

  async function onSubmit(values: FormValues) {
    const { email, password, firstName, lastName, tenantName } = values

    setIsLoading(true)
    await register(
      email,
      password,
      {
        email,
        given_name: firstName,
        family_name: lastName,
      },
      {
        tenantName,
      },
    )
    setIsLoading(false)
  }

  return (
    <Box display="flex" p="0 110px 39px" style={{ flex: 1, flexFlow: 'column', padding: '20px 30px', zIndex: 100 }}>
      <Box flex={1}>
        <Box mt={66} mb={40} display="flex" w="100%" style={{ justifyContent: 'center', flexDirection: 'column' }}>
          <Text mb={12} fw={700} size="40px">
            {t('register.welcome')}
          </Text>
          <Text fw={400} lh="1.375rem">
            {t('register.please-register')}
          </Text>
        </Box>
        <RegisterForm form={form} isLoading={isLoading} onSubmit={onSubmit} />
      </Box>
      <Box flex={0}>
        <Text c="#909296" ta="center" size=".875rem">
          {t('register.already-have-account')}{' '}
          <Anchor href="/session/login" size=".875rem" c="#2c2e33">
            {t('register.login')}
          </Anchor>
        </Text>
      </Box>
    </Box>
  )
}

interface RegisterFormProps {
  form: UseFormReturnType<FormValues, (values: FormValues) => FormValues>
  onSubmit: (values: FormValues) => Promise<void>
  isLoading: boolean
}

function RegisterForm({ form, isLoading, onSubmit }: RegisterFormProps) {
  const { t } = useTranslation(NS.session)

  const value = form.values.password

  return (
    <Box display="flex" w="100%">
      <form style={{ width: '100%' }} onSubmit={form.onSubmit(onSubmit)}>
        <Box mb={20} display="flex">
          <TextInput w="100%" mr={8} label={t('register.firstName') || ''} {...form.getInputProps('firstName')} />
          <TextInput w="100%" label={t('register.lastName') || ''} {...form.getInputProps('lastName')} />
        </Box>
        <TextInput mb={20} w="100%" label={t('register.companyName') || ''} {...form.getInputProps('tenantName')} />
        <TextInput mb={20} w="100%" type="email" label={t('register.email') || ''} {...form.getInputProps('email')} />
        <TextInput mb={20} w="100%" type="password" label={t('register.password') || ''} {...form.getInputProps('password')} />
        <PasswordProgress value={value} />
        <TextInput
          mb={33}
          w="100%"
          type="password"
          label={t('register.confirmPassword') || ''}
          {...form.getInputProps('confirmPassword')}
        />

        <Button w="100%" mb={8} color="#868E96" radius="sm" type="submit" loading={isLoading}>
          <Text size=".875rem">{t(`${NS.translation}:common.register`)}</Text>
        </Button>
      </form>
    </Box>
  )
}
