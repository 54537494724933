/* eslint-disable @typescript-eslint/prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios, { AxiosRequestConfig } from 'axios'
import { errorInterceptor, requestInterceptor, responseInterceptor } from './interceptors'
import { AuthSession, fetchAuthSession } from 'aws-amplify/auth'

let axiosRequestId: number | undefined

export function setDefaultUrl(url: string): void {
  axios.defaults.baseURL = url
}

export function setRequestInterceptor(logoutCallback: () => Promise<void>): void {
  // callback that is called every time we make a request
  // validates the session and assign the bearer token if the session is valid
  // else throw and cancel the call made
  // todo fix ts error here
  // @ts-ignore
  axiosRequestId = axios.interceptors.request.use(async (config) => {
    let newConfig
    try {
      newConfig = await getOrRefreshToken(config)
    } catch (error) {
      await logoutCallback()
      return Promise.reject(error)
    }
    return newConfig
  })
}

async function getOrRefreshToken(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  const newConfig = { ...config }
  const currentSession: AuthSession = await fetchAuthSession()
  if (!currentSession || !currentSession.tokens?.idToken) {
    throw new Error('No session')
  }
  newConfig.headers = {
    Authorization: `Bearer ${currentSession.tokens.idToken.toString()}`,
  }
  return newConfig
}

export function resetRequestInterceptor(): void {
  if (axiosRequestId !== 0 && !axiosRequestId) {
    return
  }
  axios.interceptors.request.eject(axiosRequestId)
  delete axios.defaults.headers.common['Authorization']
}

axios.interceptors.request.use(requestInterceptor)
axios.interceptors.response.use(responseInterceptor, errorInterceptor)

export default axios
