import { useEffect, useState, useContext, createContext, useCallback, useMemo } from 'react'
import { fetchAuthSession, JWT, signIn, signInWithRedirect, signOut, signUp } from 'aws-amplify/auth'
import axios, { setRequestInterceptor } from '../config/axios'
import { configureAmplify } from '../config/amplify/auth'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { showNotification } from '@mantine/notifications'
import { useCognitoInfo } from 'api/query/cognito'
import { UserToken } from 'api/dto/user-token'
import { useGetTenantMe } from 'api/query/tenant'
import { globalQueryClient } from 'api/client'
import { AccessType } from 'api/dto/access'
import { encryptData } from 'utils/crypto'

const authAxios = axios.create()
authAxios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL as string

interface ICognitoUser {
  email: string
  given_name: string
  family_name: string
}
enum ChallengeName {
  NewPasswordRequired = 'NEW_PASSWORD_REQUIRED',
}

const authContext = createContext<ReturnType<typeof useProvideAuth>>({} as never)

export function ProvideAuth(): JSX.Element {
  const currentAuth = useProvideAuth()
  return (
    <authContext.Provider value={currentAuth}>
      <Outlet />
    </authContext.Provider>
  )
}

export const useAuth = () => useContext(authContext)

const useProvideAuth = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { data: infos, isLoading: isInfosLoading } = useCognitoInfo()

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [federatedSignInLoading, setFederatedSignIn] = useState(false)
  const [isHydrated, setIsHydrated] = useState(false)
  const { data: tenant, isLoading: isLoadingTenant, refetch: getTenant } = useGetTenantMe(false)

  const [user, setUser] = useState<UserToken | null>(null)

  const appIsHydrated = useMemo(() => isHydrated, [isHydrated])

  const logout = useCallback(async (): Promise<void> => {
    await signOut()
    globalQueryClient.clear()
    setIsAuthenticated(false)
    navigate('/session/login')
  }, [navigate])

  const extractAndSetUser = useCallback(async () => {
    const session = await fetchAuthSession()
    setRequestInterceptor(logout)

    if (!session) {
      return
    }

    const user = extractUserFromToken(session.tokens?.idToken as JWT)

    setUser(user)
    setIsAuthenticated(true)
  }, [logout])

  const hydrateUser = useCallback(async () => {
    if (isInfosLoading) {
      return
    }

    configureAmplify(infos)

    try {
      await extractAndSetUser()
      await getTenant()
    } catch (error) {
      // console.log("no user")
    }

    if (location.pathname.includes('/change-password')) {
      navigate('/session/login')
    }

    setIsHydrated(true)
  }, [isInfosLoading, infos, location, extractAndSetUser, navigate, getTenant])

  const login = useCallback(
    async (email: string, password: string): Promise<void> => {
      try {
        const signInResponse = await signIn({ username: email.toLowerCase(), password })
        if (signInResponse.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
          navigate('/session/change-password')
          return
        }
        const session = await fetchAuthSession()
        if (session && session.tokens?.idToken) {
          setRequestInterceptor(logout)
          setIsAuthenticated(true)
          setUser(extractUserFromToken(session.tokens?.idToken))
        } else {
          showNotification({
            title: 'Login Failed',
            message: 'Seems like the email or password is not valid, please try again.',
            color: 'red',
          })
        }
      } catch (error: any) {
        showNotification({
          title: 'Login Failed',
          message: 'Seems like the email or password is not valid, please try again.',
          color: 'red',
        })
      }
    },
    [navigate, logout],
  )

  const register = async (email: string, password: string, user: ICognitoUser, metadata?: Record<string, string>) => {
    try {
      await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            ...user,
          },
          clientMetadata: metadata,
        },
      })

      await login(email, password)
    } catch (error: any) {
      if (error.code === 'UsernameExistsException') {
        showNotification({
          title: 'Sign Up Failed',
          message: 'User already exists, please try to login.',
          color: 'red',
        })
      } else {
        showNotification({
          title: 'Sign Up Failed',
          message: 'An error occurred, please try again.',
          color: 'red',
        })
      }
    }
  }

  const completeActivation = useCallback(
    async (encryptedData: string, password: string): Promise<void> => {
      try {
        const encryptedPassword = encryptData({ password }, encryptedData)
        const { data: email } = await authAxios.post('/users/activate', { encryptedData, encryptedPassword })
        showNotification({
          message: 'Password has been changed successfully',
          color: 'green',
        })

        await login(email, password)
      } catch (error: any) {
        showNotification({
          autoClose: false,
          message: 'Activation failed, the link might be expired, please contact support at support@unicorne.cloud.',
          color: 'red',
        })
      }
    },
    [login],
  )

  const forgotPassword = useCallback(async (email: string): Promise<void> => {
    try {
      await authAxios.post('/users/forgot-password', { email })
      showNotification({
        title: 'Forgot Password',
        message: 'A link has been sent to your email, please check your email.',
        color: 'blue',
      })
    } catch (error) {
      showNotification({
        title: 'Forgot Password',
        message: 'Seems like the email is not valid, please enter a valid email.',
        color: 'red',
      })
    }
  }, [])

  const completeForgotPassword = useCallback(
    async (encryptedData: string, encryptedPassword: string): Promise<void> => {
      try {
        await authAxios.post('/users/complete-forgot-password', { encryptedData, encryptedPassword })
        showNotification({
          title: 'Change Password',
          message: 'Password has been changed successfully',
          color: 'green',
        })
        navigate('/session/login')
      } catch (error: any) {
        if (error.code === 'CodeMismatchException') {
          showNotification({
            title: 'Change Password',
            message: 'An error occurred, please try again',
            color: 'red',
          })
        }
        showNotification({
          title: 'Change Password',
          message: 'An error occurred, please try again',
          color: 'red',
        })
      }
    },
    [navigate],
  )

  const redirectToTheRightPage = useCallback(() => {
    if ((!isAuthenticated && location.pathname.includes('/session')) || isLoadingTenant) {
      return
    }

    if (!isAuthenticated || !tenant) {
      navigate('/session/login')
      return
    }

    if (isAuthenticated && tenant && location.pathname.includes('/session')) {
      navigate('/dashboard')
      return
    }
  }, [location.pathname, isAuthenticated, tenant, navigate, isLoadingTenant])

  const signInWithGoogle = async () => {
    setFederatedSignIn(true)
    await signInWithRedirect({
      provider: 'Google',
    })
  }

  const refresh = async () => {
    const session = await fetchAuthSession({ forceRefresh: true })
    if (!session) {
      throw new Error('No session')
    }
  }

  const completeSignInWithGoogle = () => {
    setIsAuthenticated(true)
    setFederatedSignIn(false)
  }

  useEffect(() => {
    if (!isHydrated) {
      void hydrateUser()
    }
  }, [hydrateUser, isHydrated])

  useEffect(() => {
    if (isHydrated && isAuthenticated && !isLoadingTenant && !tenant) {
      void getTenant()
    }
  }, [isHydrated, isLoadingTenant, tenant, getTenant, isAuthenticated])

  useEffect(() => {
    if (isHydrated) {
      redirectToTheRightPage()
    }
  }, [isHydrated, redirectToTheRightPage])

  return {
    user,
    login,
    completeActivation,
    logout,
    refresh,
    appIsHydrated,
    register,
    forgotPassword,
    completeForgotPassword,
    isAuthenticated,
    signInWithGoogle,
    completeSignInWithGoogle,
    federatedSignInLoading,
  }
}

export function extractUserFromToken(token: JWT) {
  const payload = token.payload as Record<string, string>

  return new UserToken({
    id: payload.userId,
    email: payload.email,
    accessType: payload.accessType as AccessType,
    tenantName: payload.tenantName,
    currentTenantId: payload.currentTenantId,
  })
}
