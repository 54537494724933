import { Amplify, ResourcesConfig } from 'aws-amplify'

export interface CognitoInfos {
  CognitoUserPoolId: string
  CognitoAppClientID: string
  CognitoIdentityPoolID: string
}
const amplify: { Auth: ResourcesConfig['Auth'] | null; isConfigured: boolean } = { Auth: null, isConfigured: false }

export const configureAmplify = (cognitoInfos?: CognitoInfos) => {
  if (!cognitoInfos) {
    return
  }
  const awsConfig: ResourcesConfig['Auth'] = {
    Cognito: {
      userPoolId: cognitoInfos.CognitoUserPoolId,
      userPoolClientId: cognitoInfos.CognitoAppClientID,
    },
  }

  if (amplify.isConfigured) {
    return awsConfig
  }

  Amplify.configure({ Auth: { ...awsConfig } })

  amplify.isConfigured = true
}
