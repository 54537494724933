import { Badge, Box, Text, Title } from '@mantine/core'
import { ColumnDef } from '@tanstack/react-table'
import { RecommendationStatus, RecommendationType } from 'api/domain/entities/recommendation'
import {
  CostOptimizationRecommendation,
  CostOptimizationRecommendationDifficulty,
  CostOptimizationResource,
} from 'api/domain/entities/recommendation/cost-optimization-recommendation'
import { useListRecommendations } from 'api/query/recommendation'
import { useGetTenantMe } from 'api/query/tenant'
import { Aside } from 'components/aside/aside'
import { AsideCostRecommendationContent } from 'components/aside/content/aside-cost-optimization-recommendation-content'
import { Loading } from 'components/loading/loading'
import { Table } from 'components/table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime, DateTimeFormat } from 'utils/date-time'
import { getDifficultyColor } from 'utils/difficulty'
import { formatCost } from 'utils/number'

export function CostOptimizationRecommendations() {
  const { t } = useTranslation()
  const { data: tenant, isLoading: isLoadingTenant } = useGetTenantMe()
  const { data: recommendations, isLoading: isLoadingRecommendations } = useListRecommendations(
    RecommendationType.COST_OPTIMIZATION,
    tenant?.id,
  )

  const [selectedRecommendation, setSelectedRecommendation] = useState<CostOptimizationRecommendation | undefined>()

  const columns: ColumnDef<CostOptimizationRecommendation>[] = [
    {
      id: 'difficulty',
      accessorKey: 'difficulty',
      header: t('cost:columns.difficulty'),
      cell: (col) => {
        const difficulty = col.getValue() as CostOptimizationRecommendationDifficulty

        const color = getDifficultyColor(difficulty)

        return (
          <Text c={color} size="sm">
            {t(`cost:fields.difficulty.${col.getValue() as string}`).toUpperCase()}
          </Text>
        )
      },
    },
    {
      id: 'title',
      accessorKey: 'translatedTitle',
      header: t('cost:columns.recommendation'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },
    {
      id: 'numberOfResourcesAffected',
      accessorKey: 'resources',
      header: t('cost:columns.resources-affected'),
      cell: (col) => {
        const resources = col.getValue() as CostOptimizationResource[]

        const unresolvedResources = resources.filter((r) => r.status === RecommendationStatus.UNRESOLVED)

        return (
          <Text size="sm">
            {unresolvedResources.length} {t('cost:fields.resource', { count: unresolvedResources.length })}
          </Text>
        )
      },
    },
    {
      id: 'savings',
      accessorKey: 'globalAnnualizedSavings',
      header: t('cost:columns.annualized-savings'),
      cell: (col) => {
        const value = col.getValue() as number
        return <Text size="sm">{formatCost(value)}</Text>
      },
    },
    {
      id: 'resolved-savings',
      accessorKey: 'globalRealizedSavings',
      header: t('cost:columns.realized-savings'),
      cell: (col) => {
        const value = col.getValue() as number
        return <Text size="sm">{formatCost(value)}</Text>
      },
    },
  ]

  if (isLoadingTenant || isLoadingRecommendations) {
    return <Loading size="xl" />
  }

  return (
    <>
      <Title order={1}>{t('pages.cost-and-usage').toUpperCase()}</Title>

      <Box mt={46}>
        <Table<CostOptimizationRecommendation>
          columns={columns}
          data={recommendations || []}
          defaultSort={{ id: 'savings', desc: true }}
          onRowClick={(r) => setSelectedRecommendation(r.original)}
        >
          <Aside close={() => setSelectedRecommendation(undefined)} opened={!!selectedRecommendation}>
            {selectedRecommendation && <AsideCostRecommendationContent costOptimizationRecommendation={selectedRecommendation} />}
          </Aside>
        </Table>
      </Box>
    </>
  )
}
